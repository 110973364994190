/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@import 'style-profile';
@import 'theme';
@import 'vars';

html,
body {
  color: $gray7;
  font-size: map.get($font_body_1, 'size');
  line-height: map.get($font_body_1, 'height');
  font-weight: map.get($font_body_1, 'weight');
  font-family: map.get($font_body_1, 'family');
  letter-spacing: map.get($font_body_1, 'spacing');
}

* {
  font-family: inherit;
  font-weight: normal;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// Main layout
html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

app-root {
  margin: 0;
  main {
    position: relative;
    padding-top: 56px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    @media (min-width: 575px) {
      padding-top: 64px;
    }
    section {
      flex-grow: 1;
      padding: 12px;
      @media (min-width: $breakpoint-phone) {
        padding: 18px;
      }
      @media (min-width: $body-max-width) {
        padding: 0px;
        padding-bottom: 18px;
      }
    }
    dash-footer {
      flex: 0 0;
    }
  }
}

// Layout helpers
.max-width {
  max-width: $body-max-width;
  margin: auto;
}

// Featured Content
.content-section {
  display: block;
  margin-top: 30px;
}

.hover-color {
  &:hover {
    background-color: $hover;
  }
}
.pointer {
  cursor: pointer;
}

// Form
.form-page {
  .form-header {
    margin: 48px 0px;
    .title {
      @extend .mat-h5;
      text-decoration: none;
      margin-right: 24px;
      color: $gray6;
      &.active {
        color: $dark;
      }
    }
    .subtitle {
      margin-top: 12px;
      font-size: 16px;
    }
  }
  .form-body {
    width: 100%;
    max-width: 612px;
    display: flex;
    flex-direction: column;
    & > .mb {
      margin-bottom: 22px;
      font-size: 16px;
    }
  }
  @media (max-width: 575px) {
    .form-header {
      margin: 12px 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
.secondary-link {
  @extend .mat-body-2;
  color: $gray7;
  font-size: 14px !important;
}

.gray-7 {
  color: $gray7;
}
.primary-link {
  color: $gray7;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.no-decoration {
  text-decoration: none;
}
.underline {
  text-decoration: underline;
}

dash-header {
  border-bottom: 1px solid $gray5;
}

dash-footer {
  border-top: 1px solid $gray5;
}

@media (max-width: 575px) {
  .mobile-hide {
    display: none !important;
  }
}

.two-cols {
  display: grid;
  @media (min-width: 575px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }
}
.m0 {
  margin: 0;
}

.dark {
  color: $dark;
}
.currency {
  font-weight: bold;
}
.contents {
  display: contents;
}
.cta-box {
  width: 100%;
  padding: 26px 22px;
  border: 1px solid $gray5;
}
.cta-colored-box {
  width: 100%;
  padding: 26px 22px;
  background-color: $gray7;
  color: white;
}
.cta-centered-box {
  text-align: center;
}
.cta-mt {
  margin-top: 24px;
}
.w-100 {
  width: 100%;
}
.block {
  display: block;
}
.mat-dialog-no-padding {
  mat-dialog-container {
    padding: 0px;
  }
}

// Styles after material migration
.mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
}

.mat-typography {
  font: 400 16px / 24px var(--main-font-family, Roboto);
  letter-spacing: normal;
}

.mat-subheading-2 {
  font: 400 48px / 56px var(--main-font-family, Roboto);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-subtitle-4 {
  font: 400 16px / 24px var(--main-font-family, Roboto);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.15px;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--accent-color, #2196f3) !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  color: white !important;
}

.mat-mdc-progress-bar {
  z-index: 10000;
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-mdc-dialog-content {
  padding: 0 !important;
}

#embeddedpayments {
  width: 100%;
  #embedded-form-container {
    width: 100%;
  }
}
