@import '../generated/ds_colors';
@import '../_colors';

// border radius for primary and accent button
button.mdc-button.mat-primary,
button.mdc-button.mat-accent,
button.mdc-button.mat-warn,
button.mdc-button.mat-success,
button.mat-mdc-raised-button.mat-primary,
button.mat-mdc-raised-button.mat-accent,
button.mat-mdc-raised-button.mat-warn,
button.mat-mdc-raised-button.mat-success,
button.mat-mdc-stroked-button.mat-primary,
button.mat-mdc-stroked-button.mat-accent,
button.mat-mdc-stroked-button.mat-warn,
button.mat-mdc-stroked-button.mat-success {
  height: 36px;
  padding: 0 16px;
  span {
    letter-spacing: normal;
    font-weight: 500;
  }
  mat-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
    &.left-icon {
      margin-right: 6px;
    }
    &.right-icon {
      margin-left: 6px;
    }
  }
}

button.mat-mdc-raised-button.mat-primary[disabled] {
  background-color: $gray6;
  span {
    color: white;
  }
}

.simple-icon {
  min-width: 0 !important;
  padding: 0 6px !important;
  mat-icon {
    margin: 0 !important;
  }
}

.mdc-button.mat-success,
.mat-stroked-button.mat-success {
  color: #389e0d !important;
}
.mdc-button.mat-success:hover,
.mat-stroked-button.mat-success:hover {
  background-color: rgba(56, 158, 13, 0.1);
}

.mat-mdc-raised-button.mat-success,
.mat-mdc-flat-button.mat-success,
.mat-mdc-fab.mat-success,
.mat-mdc-mini-fab.mat-success {
  color: rgba(56, 158, 13, 0.1);
  background-color: #389e0d;
}

.mat-mdc-icon-button.mat-success {
  color: #389e0d;
}
