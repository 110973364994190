@use "sass:map";
@import 'node_modules/@angular/material/theming';

.mat-subtitle-1 {
    font-family: var(--main-font-family, 'Roboto');
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.mat-subtitle-2 {
    font-family: var(--main-font-family, 'Roboto');
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.mat-subtitle-3 {
    font-family: var(--main-font-family, 'Roboto');
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.mat-subtitle-4 {
    font-family: var(--main-font-family, 'Roboto');
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.15px;
}
