@use "sass:map";
@import 'node_modules/@angular/material/theming';

.mat-h6, h6 {
    font-family: var(--main-font-family, 'Roboto');
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
}
