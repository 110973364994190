@import '../generated/ds_colors';

.success-snackbar {
  background-color: #e3f2fd !important;
  --mdc-snackbar-container-color: #e3f2fd !important;
  color: $dash-accent !important;
  --mdc-snackbar-supporting-text-color: $dash-accent !important;
}

.information-snackbar {
  background-color: #ffffff !important;
  --mdc-snackbar-container-color: #ffffff !important;
  color: $dash-accent !important;
  --mdc-snackbar-supporting-text-color: $dash-accent !important;
  max-width: 90vh !important;
}

.error-snackbar {
  background-color: white !important;
  --mdc-snackbar-container-color: white !important;
  color: #c7536f !important;
  --mdc-snackbar-supporting-text-color: #c7536f !important;
}
