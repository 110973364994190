@use "sass:map";

@import "../generated/ds_text_styles";
@import "../generated/ds_colors";


.mat-form-field-flex > .mat-form-field-infix { padding: 0.2em 0px 0.6em !important}
.mat-form-field-label-wrapper { top: -1.5em; }

.mat-form-field-appearance-outline .mat-form-field-suffix { top: -0.1em !important; color: $input-inactive; }

.mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.5em !important;
    .mat-label, .mat-form-field-required-marker {
        padding-right: 6px !important;
        background-color: white !important;
    }
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.4em) scale(.75) !important;
    width: 133.33333%;
}

.mat-form-field .mat-input-element,
.mat-form-field .mat-select,
.mat-form-field .mat-select-value-text span {
    font-stretch: normal;
    font-style: normal;
    font-size: map.get($font_body_1, "size");
    line-height: map.get($font_body_1, "height");
    font-weight: map.get($font_body_1, "weight");
    font-family: map.get($font_body_1, "family");
    letter-spacing: map.get($font_body_1, "spacing");
    color: $input-active !important;
    margin: 3px 0px;
}

.mat-form-field .mat-select .mat-select-arrow-wrapper {
  vertical-align: baseline !important;
}

.mat-form-field {
    line-height: normal !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline{
  color: $input-inactive !important;
}

.mat-form-field:hover {
  .mat-form-field-label {
    color: $input-focused !important;
  }
}

.mat-form-field-label {
  color: $input-focused !important;
}


.mat-form-field.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-should-float {
  .mat-form-field-label, .mat-form-field-outline-thick, .mat-form-field-required-marker{
    color: $error !important;
  }
}


.mat-form-field-appearance-outline.mat-focused {
  .mat-form-field-label, .mat-form-field-outline-thick, .mat-form-field-required-marker {
      color: $dash-accent !important;
  }
}

.mat-error {
  color: $error !important;
}

.search-input {
  margin-top: 5px;
  input {
    font-size: 14px !important;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
    min-width: 350px;
    .mat-mdc-form-field-flex {
      align-items: center;
      .mat-mdc-form-field-infix {
        padding: 0px !important;
        min-height: fit-content !important;
      }
    }
    .mat-mdc-form-field-icon-prefix {
      mat-icon {
        height: 20px;
        width: 20px;
        font-size: 20px;
        color: $gray6;
      }
    }
    .mat-mdc-form-field-icon-suffix {
      mat-icon {
        height: 20px;
        width: 20px;
        font-size: 20px;
        color: $input-active;
        cursor: pointer;
      }
    }
  }
}


