$white: #ffffff;
$background: #fbfcfd;
$gray: #8b8b8b;
$gray5: #e5e5e5;
$gray6: #b6bbbf;
$gray7: #58646e;
$dark: #303f4b;
$dash-accent: var(--accent-color, #2196f3);
$gray-divider: #e5e5e5;
$input-inactive: rgba(0, 0, 0, 0.12);
$input-focused: rgba(0, 0, 0, 0.6);
$input-active: rgba(0, 0, 0, 0.87);
$error: #b00020;
$hover: #f5f5f5;
$lightBlue: #EEF7FE;
