@use "sass:map";
@import 'node_modules/@angular/material/theming';

.mat-h5, h5 {
    font-family: var(--main-font-family, Roboto);
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.18px !important;
}
