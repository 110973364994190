.c-g5 {
  color: $gray5;
}
.c-g6 {
  color: $gray6;
}
.c-g7 {
  color: $gray7;
}
.c-dark {
  color: $dark;
}
.c-accent {
  color: $dash-accent;
}
