@import '../generated/ds_colors';

.mdc-list {
  padding: 8px !important;
}

mat-nav-list {

    .mat-mdc-subheader {
        height: 1.5rem !important;
        font-size: 12px !important;
        line-height: 14.06px !important;
        font-weight: 700 !important;
        letter-spacing: 0.4px !important;
        padding: 0 0 0 8px !important;
        margin: 0 !important;
        color: $gray7 !important;
    }

    & .mat-mdc-list-item {
        height: 40px !important;
        min-width: 239px;
        margin-bottom: 8px;
    }
    & .mat-mdc-list-item:hover {
       border-radius: 4px;
    }
    & .mdc-list-item__content {
        font-family: var(--main-font-family, 'Roboto');
        font-size: 14px !important;
        font-weight: 500 ;
        line-height: 21px;
        letter-spacing: 0.1px;
        mat-icon {
            margin-right: 31px;
            color: $gray7;
        }
    }
    & .mat-list-item-is-active,
    & .mat-mdc-list-item:focus {
        background-color: #E3F2FD !important;
        border-radius: 4px;
        .mdc-list-item__content {
            span {
              color: $dash-accent !important;
            }
            mat-icon {
                color: $dash-accent !important;
            }
        }
    }
}
